import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Educations from "../../containers/education/Educations";
import Certifications from "../../containers/certifications/Certifications";
import EducationImg from "./EducationImg";
import "./EducationComponent.css";
import { Fade } from "react-reveal";

function Education(props) {
  const theme = props.theme;
  return (
    <div className="education-main">
      <Header theme={props.theme} setTheme={props.setTheme} />
      <div className="basic-education">
        <Fade bottom duration={2000} distance="40px">
          <div className="heading-div">
            <div className="heading-img-div">
              <EducationImg theme={theme} />
            </div>
            <div className="heading-text-div">
              <h1 className="heading-text" style={{ color: theme.text }}>
                Education
              </h1>
              <p
                className="experience-header-detail-text subTitle"
                style={{ color: theme.secondaryText,  }}
              >
                I have pursued both national and international education, gaining a comprehensive understanding of technology and its applications on a global scale.
                This diverse academic experience has provided me with a strong foundation in Software Engineering and Cybersecurity & Forensic IT, which has equipped me with a deep understanding of fields that are critical to the future of AI, data security, and emerging technologies.
                These fields are at the forefront of innovation, driving advancements in automation, machine learning, and secure systems.
              </p>
            </div>
          </div>
        </Fade>
        <Educations theme={props.theme} />
        <Certifications theme={props.theme} />
      </div>
      <Footer theme={props.theme} />
    </div>
  );
}

export default Education;
