/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Arslan",
  logo_name: "Arslan.Z()",
  nickname: "Arslan / goku",
  full_name: "Arslan Zaheer (8+ Years of Professional Experience)",
  subTitle: "Sr. Full Stack Developer & CyberSecurity Engineer, Always ready to build Some new and Complex Solutions",
  resumeLink:
    "https://drive.google.com/file/d/1nOlHlC09E-fkd-W3djr4WP0grNezdm3k/view",
  mail: "mailto:arslanzaheer977@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/arslan578",
  linkedin: "https://www.linkedin.com/in/arslanzaheer437/",
  gmail: "arslanzaheer977@gmail.com",
  gitlab: "https://gitlab.com/arslan578",
  facebook: "https://www.facebook.com/arsalan578/",
  twitter: "https://twitter.com/ArslanR09197358",
  instagram: "https://www.instagram.com/arslann_zaheer/",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Proficient in architecting and developing full-stack web applications with modern frameworks like React, Angular, and Vue.js.",
        "⚡ Expertise in crafting scalable, efficient backend services using Django, DRF, Node.js, Express, Flask, and FastAPI.",
        "⚡ Experienced in developing mobile applications with Flutter and Android for cross-platform compatibility.",
        "⚡ Adept at implementing blockchain technology and SaaS solutions to drive innovation in distributed systems.",
        "⚡ Strong foundation in modern web technologies, including REST APIs, GraphQL, and WebSockets for real-time communication.",
        "⚡ Proficient in optimizing performance and user experience through robust frontend and backend solutions."
      ],

      softwareSkills : [

        {
          skillName: "BlockChain",
          fontAwesomeClassname: "simple-icons:blockchaindotcom",
          style: {
            color: "#3178C6",
          },
        },
        {
          skillName: "SAAS",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },{
          skillName: "FastAPI",
          fontAwesomeClassname: "simple-icons:fastapi",
          style: {
            color: "#009688",
          },
        },{
          skillName: "BlockChain",
          fontAwesomeClassname: "simple-icons:blockchaindotcom",
          style: {
            color: "#3178C6",
          },
        },

        {
          skillName: "TypeScript",
          fontAwesomeClassname: "simple-icons:typescript",
          style: {
            color: "#3178C6",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#DD0031",
          },
        },
        {
          skillName: "VueJS",
          fontAwesomeClassname: "simple-icons:vuedotjs",
          style: {
            color: "#4FC08D",
          },
        },

        {
          skillName: "Tailwind CSS",
          fontAwesomeClassname: "simple-icons:tailwindcss",
          style: {
            color: "#06B6D4",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:nodedotjs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "ExpressJS",
          fontAwesomeClassname: "simple-icons:express",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#092E20",
          },
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "simple-icons:flask",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#E10098",
          },
        },
        {
          skillName: "Insomnia",
          fontAwesomeClassname: "simple-icons:insomnia",
          style: {
            color: "#4000BF",
          },
        },
        {
          skillName: "WordPress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#21759B",
          },
        },
        {
          skillName: ".Net",
          fontAwesomeClassname: "simple-icons:dotnet",
          style: {
            color: "#A8B9CC",
          },
        },
        {
          skillName: "Ruby On Rails",
          fontAwesomeClassname: "simple-icons:rubyonrails",
          style: {
            color: "#D30001",
          },
        },
      ],
    },
    {
      title: "CyberSecurity & Forensic IT",
      fileName: "CYBER",
      skills: [
        "⚡ Expertise in penetration testing, vulnerability assessments, and ethical hacking to secure web applications, networks, and cloud infrastructures as a Red Team operator.",
        "⚡ Proficient in forensic analysis, malware detection, and reverse engineering to investigate and mitigate security incidents effectively, providing in-depth post-attack analysis.",
        "⚡ Deep knowledge of cryptographic algorithms and secure communication protocols to protect sensitive data across digital environments from adversarial threats.",
        "⚡ Implement advanced security policies and frameworks, such as SOC2, ISO 27001, GDPR, and PCI-DSS, ensuring compliance and regulatory standards, while simulating real-world attack scenarios.",
        "⚡ Experience with intrusion detection systems, threat intelligence platforms, and real-time monitoring tools to prevent, detect, and respond to cyber threats, improving Blue Team defenses.",
        "⚡ Mastery of Red Team cybersecurity tools like Metasploit, Wireshark, Burp Suite, OpenVAS, and custom exploit development for simulating adversarial tactics, techniques, and procedures (TTPs)."
      ],

      softwareSkills: [
        {
          skillName: "Penetration Testing",
          fontAwesomeClassname: "simple-icons:kalilinux",
          style: {
            color: "#557C94",
          },
        },
        {
          skillName: "Forensics",
          fontAwesomeClassname: "simple-icons:springsecurity",
          style: {
            color: "#6DB33F", // Alternate forensic tool logo color
          },
        },
        {
          skillName: "Ethical Hacking",
          fontAwesomeClassname: "simple-icons:cyberdefenders",
          style: {
            color: "#157B79",
          },
        },
        {
          skillName: "Cryptography",
          fontAwesomeClassname: "simple-icons:openssl",
          style: {
            color: "#721421",
          },
        },
        {
          skillName: "Red Team",
          fontAwesomeClassname: "simple-icons:redhat",
          style: {
            color: "#EE0000",
          },
        },
        {
          skillName: "Compliance",
          fontAwesomeClassname: "fa-solid:shield-alt",
          style: {
            color: "#4CAF50",
          },
        },
        {
          skillName: "Policy Documents",
          fontAwesomeClassname: "fa-solid:file-alt",
          style: {
            color: "#00BFFF",
          },
        },
        {
          skillName: "Privacy & Compliance",
          fontAwesomeClassname: "fa-solid:user-shield",
          style: {
            color: "#FF6347",
          },
        },
        {
          skillName: "Security & Compliance",
          fontAwesomeClassname: "fa-solid:lock",
          style: {
            color: "#000",
          },
        },
        {
          skillName: "Contracts & Compliance",
          fontAwesomeClassname: "fa-solid:file-contract",
          style: {
            color: "#FF4500",
          },
        },

      ]

    },
    {
      title: "AI & Data Engineering",
      fileName: "AI",
      skills: [
        "⚡ Expertise in building machine learning models using TensorFlow and PyTorch for predictive analytics, computer vision, and natural language processing.",
        "⚡ Proficient in designing scalable data pipelines using Apache Spark, Hadoop, and ETL frameworks to process large volumes of structured and unstructured data.",
        "⚡ Skilled in advanced data visualization techniques using Tableau and Matplotlib to deliver impactful insights and reports for decision-making.",
        "⚡ Hands-on experience with data manipulation and analysis using Pandas, Numpy, and SciKit-Learn for statistical modeling and deep learning.",
        "⚡ Knowledgeable in deploying AI and machine learning models into production environments, leveraging cloud services like AWS, GCP, and Azure.",
        "⚡ Strong foundation in big data technologies and real-time data processing architectures for creating robust, data-driven applications."
      ],

      softwareSkills: [

        {
          skillName: "TensorFlow",
          fontAwesomeClassname: "simple-icons:tensorflow",
          style: {
            color: "#FF6F00",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "simple-icons:pytorch",
          style: {
            color: "#EE4C2C",
          },
        },
        {
          skillName: "Apache Spark",
          fontAwesomeClassname: "simple-icons:apachespark",
          style: {
            color: "#E25A1C",
          },
        },
        {
          skillName: "Hadoop",
          fontAwesomeClassname: "simple-icons:apachehadoop",
          style: {
            color: "#66CCFF",
          },
        },
        {
          skillName: "Tableau",
          fontAwesomeClassname: "simple-icons:tableau",
          style: {
            color: "#E97627",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            color: "#D00000",
          },
        },
        {
          skillName: "Jupyter",
          fontAwesomeClassname: "simple-icons:jupyter",
          style: {
            color: "#F37626",
          },
        },
        {
          skillName: "Pandas",
          fontAwesomeClassname: "simple-icons:pandas",
          style: {
            color: "#150458",
          },
        },
        {
          skillName: "Numpy",
          fontAwesomeClassname: "simple-icons:numpy",
          style: {
            color: "#013243",
          },
        },
        {
          skillName: "SciKit-Learn",
          fontAwesomeClassname: "simple-icons:scikitlearn",
          style: {
            color: "#F7931E",
          },
        },
        {
          skillName: "ETL",
          fontAwesomeClassname: "simple-icons:etl",
          style: {
            color: "#4CAF50",
          },
        },
        {
          skillName: "Data Pipeline",
          fontAwesomeClassname: "simple-icons:pipeline",
          style: {
            color: "#FF5733",
          },
        },



      ],

    },
    {
      title: "Database Management",
      fileName: "DatabaseManagementImg",
      skills: [
        "⚡ Extensive experience with both relational and NoSQL databases, ensuring data integrity and scalability.",
        "⚡ Expertise in database design, optimization, and query performance tuning for high-load systems.",
        "⚡ Proficient in using popular databases such as MySQL, PostgreSQL, MongoDB, and Oracle to manage complex datasets.",
        "⚡ Skilled in implementing data replication, backup, and disaster recovery strategies to ensure high availability."
      ],

      softwareSkills: [
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Microsoft SQL Server",
          fontAwesomeClassname: "simple-icons:microsoftsqlserver",
          style: {
            color: "#CC2927",
          },
        },
        {
          skillName: "Oracle",
          fontAwesomeClassname: "simple-icons:oracle",
          style: {
            color: "#F80000",
          },
        },
        {
          skillName: "Redis",
          fontAwesomeClassname: "simple-icons:redis",
          style: {
            color: "#DC382D",
          },
        },
        {
          skillName: "Cassandra",
          fontAwesomeClassname: "simple-icons:apachecassandra",
          style: {
            color: "#1287B1",
          },
        },
        {
          skillName: "SQLite",
          fontAwesomeClassname: "simple-icons:sqlite",
          style: {
            color: "#003B57",
          },
        },
        {
          skillName: "Elasticsearch",
          fontAwesomeClassname: "simple-icons:elasticsearch",
          style: {
            color: "#005571",
          },
        },
        {
          skillName: "Amazon DynamoDB",
          fontAwesomeClassname: "simple-icons:amazondynamodb",
          style: {
            color: "#4053D6",
          },
        },
        {
          skillName: "CouchDB",
          fontAwesomeClassname: "simple-icons:apachecouchdb",
          style: {
            color: "#E4002B",
          },
        },
        {
          skillName: "CockroachDB",
          fontAwesomeClassname: "simple-icons:cockroachlabs",
          style: {
            color: "#6933FF",
          },
        },
        {
          skillName: "Neo4j",
          fontAwesomeClassname: "simple-icons:neo4j",
          style: {
            color: "#008CC1",
          },
        },

        {
          skillName: "FaunaDB",
          fontAwesomeClassname: "simple-icons:fauna",
          style: {
            color: "#3B2750",
          },
        },
        {
          skillName: "MariaDB",
          fontAwesomeClassname: "simple-icons:mariadb",
          style: {
            color: "#003545",
          },
        },
      ],

    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Extensive experience working on multiple cloud platforms including AWS, Azure, and Google Cloud.",
        "⚡ Expertise in hosting, managing websites, and deploying applications using platforms like Heroku, Netlify, and Firebase.",
        "⚡ Strong skills in Continuous Integration/Continuous Deployment (CI/CD) pipelines using Jenkins, GitHub Actions, and Docker for automation and containerization.",
        "⚡ Deep knowledge of Kubernetes for orchestrating scalable and fault-tolerant applications in production environments.",
        "⚡ Hands-on experience with infrastructure as code tools like Terraform to automate cloud infrastructure setup and configuration.",
      ],

      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
        {
          skillName: "Google Cloud",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "simple-icons:jenkins",
          style: {
            color: "#D24939",
          },
        },
        {
          skillName: "Terraform",
          fontAwesomeClassname: "simple-icons:terraform",
          style: {
            color: "#5C4EE5",
          },
        },
        {
          skillName: "Ansible",
          fontAwesomeClassname: "simple-icons:ansible",
          style: {
            color: "#EE0000",
          },
        },
        {
          skillName: "Prometheus",
          fontAwesomeClassname: "simple-icons:prometheus",
          style: {
            color: "#E6522C",
          },
        },
        {
          skillName: "Grafana",
          fontAwesomeClassname: "simple-icons:grafana",
          style: {
            color: "#F46800",
          },
        },
        {
          skillName: "ElasticSearch",
          fontAwesomeClassname: "simple-icons:elasticsearch",
          style: {
            color: "#005571",
          },
        },
        {
          skillName: "Apache Kafka",
          fontAwesomeClassname: "simple-icons:apachekafka",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Nginx",
          fontAwesomeClassname: "simple-icons:nginx",
          style: {
            color: "#009639",
          },
        },
      ],


    },
    {
      title: "Monitoring, Logging Tools ",
      fileName: "monitor",
      skills: [
        "⚡ Proficient in monitoring and logging tools like Grafana, Prometheus, and ELK Stack for real-time insights and data-driven decision making.",
        "⚡ Expertise in security monitoring tools including Splunk, SolarWinds, and Nagios for proactive threat detection and system integrity.",
        "⚡ Hands-on experience with security information and event management (SIEM) platforms like ArcSight and LogRhythm to monitor, detect, and respond to security incidents.",
        "⚡ Strong knowledge of cloud-based security monitoring with AWS CloudWatch and Azure Monitor to maintain security and performance.",
        "⚡ Experience with automated vulnerability scanning tools like Nessus, OpenVAS, and Qualys for identifying security risks and compliance breaches.",
        "⚡ In-depth understanding of intrusion detection and prevention systems (IDS/IPS) such as Snort and OSSEC to protect critical infrastructures.",
      ],


      softwareSkills: [
        {
          skillName: "Nagios",
          fontAwesomeClassname: "simple-icons:nagios",
          style: {
            color: "#00ACD7",
          },
        },
        {
          skillName: "Grafana",
          fontAwesomeClassname: "simple-icons:grafana",
          style: {
            color: "#F46800",
          },
        },
        {
          skillName: "Prometheus",
          fontAwesomeClassname: "simple-icons:prometheus",
          style: {
            color: "#E6522C",
          },
        },
        {
          skillName: "ELK Stack",
          fontAwesomeClassname: "simple-icons:elastic",
          style: {
            color: "#005571",
          },
        },
        {
          skillName: "Splunk",
          fontAwesomeClassname: "simple-icons:splunk",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "New Relic",
          fontAwesomeClassname: "simple-icons:newrelic",
          style: {
            color: "#008C99",
          },
        },
        {
          skillName: "Datadog",
          fontAwesomeClassname: "simple-icons:datadog",
          style: {
            color: "#632CA6",
          },
        },
        {
          skillName: "Zabbix",
          fontAwesomeClassname: "fa-server",
          style: {
            color: "#DB001B",
          },
        },
        {
          skillName: "PagerDuty",
          fontAwesomeClassname: "simple-icons:pagerduty",
          style: {
            color: "#06AC38",
          },
        },
        {
          skillName: "Sysdig",
          fontAwesomeClassname: "simple-icons:dynatrace",
          style: {
            color: "#1496FF",
          },
        },
        {
          skillName: "Checkmk",
          fontAwesomeClassname: "simple-icons:checkmk",
          style: {
            color: "#20BC60",
          },
        },
        {
          skillName: "Sumo Logic",
          fontAwesomeClassname: "simple-icons:sumologic",
          style: {
            color: "#FF4700",
          },
        },
        {
          skillName: "AppDynamics",
          fontAwesomeClassname: "simple-icons:appdynamics",
          style: {
            color: "#007CC1",
          },
        },
        {
          skillName: "OpenVAS",
          fontAwesomeClassname: "simple-icons:openvas",
          style: {
            color: "#75A700",
          },
        }
      ],



    },


    {
      title: "Version Control & Management",
      fileName: "GIT",
      skills: [
        "⚡ Proficient in version control systems like Git for efficient collaboration and code management.",
        "⚡ Extensive experience with project management tools like Jira and Trello for task tracking and team coordination.",
      ],

      softwareSkills: [// Version Control
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#F05032",
          },
        },
        {
          skillName: "GitHub",
          fontAwesomeClassname: "simple-icons:github",
          style: {
            color: "#181717",
          },
        },
        {
          skillName: "GitLab",
          fontAwesomeClassname: "simple-icons:gitlab",
          style: {
            color: "#FC6D26",
          },
        },
        {
          skillName: "Bitbucket",
          fontAwesomeClassname: "simple-icons:bitbucket",
          style: {
            color: "#0052CC",
          },
        },
        {
          skillName: "Subversion",
          fontAwesomeClassname: "simple-icons:subversion",
          style: {
            color: "#809CC9",
          },
        },

// Communication Software
        {
          skillName: "Slack",
          fontAwesomeClassname: "simple-icons:slack",
          style: {
            color: "#4A154B",
          },
        },
        {
          skillName: "Microsoft Teams",
          fontAwesomeClassname: "simple-icons:microsoftteams",
          style: {
            color: "#6264A7",
          },
        },
        {
          skillName: "Zoom",
          fontAwesomeClassname: "simple-icons:zoom",
          style: {
            color: "#2D8CFF",
          },
        },
        {
          skillName: "Discord",
          fontAwesomeClassname: "simple-icons:discord",
          style: {
            color: "#5865F2",
          },
        },

// JetBrains Tools for Python, Go, Data Engineering, Ruby, and Node.js
        {
          skillName: "PyCharm",
          fontAwesomeClassname: "simple-icons:pycharm",
          style: {
            color: "#21D789",
          },
        },
        {
          skillName: "GoLand",
          fontAwesomeClassname: "simple-icons:goland",
          style: {
            color: "#00ADD8",
          },
        },
        {
          skillName: "DataGrip",
          fontAwesomeClassname: "simple-icons:datagrip",
          style: {
            color: "#C53F82",
          },
        },
        {
          skillName: "RubyMine",
          fontAwesomeClassname: "simple-icons:rubymine",
          style: {
            color: "#AF0F31",
          },
        },
        {
          skillName: "WebStorm",
          fontAwesomeClassname: "simple-icons:webstorm",
          style: {
            color: "#00BFFF",
          },
        },
        {
          skillName: "IntelliJ IDEA",
          fontAwesomeClassname: "simple-icons:intellijidea",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "PhpStorm",
          fontAwesomeClassname: "simple-icons:phpstorm",
          style: {
            color: "#4D76CD",
          },
        },
        {
          skillName: "Rider",
          fontAwesomeClassname: "simple-icons:rider",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Jira",
          fontAwesomeClassname: "simple-icons:jira",
          style: {
            color: "#0052CC",
          },
        },

      ],
    },

  ],
};

const degrees = {
  degrees: [
    {
      title: "University of Portsmouth",
      subtitle: "Master's in Cybersecurity and Forensic Information Technology",
      logo_path: "ssec.png",
      alt_name: "UOP",
      duration: "Jan 2022 - Aug 2023",
      descriptions: [
        "⚡ Mastered parallel disciplines in digital security and forensic investigation, expanding my career options in a connected world that demands advanced cyber skills.",
        "⚡ Gained hands-on experience with specialist facilities, including multi-platform network suites, high-performance computing labs, and forensic labs with crime scene simulations.",
        "⚡ Investigated real-life case studies, identified digital trails, compiled evidence, and engaged with ethical hacking using forensic tools like AccessData, Cellebrite, and TryHackMe.",
        "⚡ Explored contemporary cybersecurity issues, such as blockchain, cryptocurrency, IoT, drones, and anti-forensic techniques, while staying up to date with evolving threats.",
        "⚡ Designed and delivered my own forensic IT engineering project, integrating formal logic, Linux, symbolic notation, penetration testing, and red teaming techniques."
      ],
      website_link: "https://www.port.ac.uk/",
    },
    {
      title: "University of The Punjab",
      subtitle: "Bachelor's in Software Engineering",
      logo_path: "ssgandhy.png",
      alt_name: "UOP",
      duration: "2015 - 2019",
      descriptions: [
        "⚡ I have studied core subjects such as Software Engineering, Object-Oriented Programming, Database Systems, Operating Systems, and Software Project Management.",
        "⚡ My coursework included hands-on projects in areas like Software Design and Architecture, Data Structures and Algorithms, and Human-Computer Interaction.",
        "⚡ I have gained practical experience by developing and managing software projects, with a strong focus on Software Quality Assurance, Agile methodologies, and Software Requirements Engineering.",
        "⚡ I have implemented several projects based on concepts learned in class, including Enterprise Systems Development, ensuring scalable and efficient solutions.",
        "⚡ I secured a strong academic performance and further enhanced my skills through specialized electives in advanced computing topics.",
      ],
      website_link: "https://pu.edu.pk/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Speaker At CyberSecurity Conference",
      subtitle: "logytalk",
      logo_path: "mongo.png",
      certificate_link:
        "https://logytalks.com/view-conference/bba85acb-2c2f-466f-862e-2320044da78c-1665054028",
      alt_name: "LogyTalk",
      // color_code: "#2AAFED",
      color_code: "#FFFFFF",
    },
    {
      title: "Internet of Things IOT, Robotics and Hacking with NodeMCU",
      subtitle: "Udemy",
      logo_path: "iit.png",
      certificate_link:
        "https://udemy-certificate.s3.amazonaws.com/pdf/UC-901254a3-84f1-4897-b3a6-665168cd0555.pdf",
      alt_name: "Workshop",
      color_code: "#2AAFED",
    },
    {
      title: "Python Django and Blazor",
      subtitle: "Udemy",
      logo_path: "iit.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-ace883ef-839f-4881-b734-b4fdd84a5110/",
      alt_name: "Workshop",
      color_code: "#2AAFED",
    },
    {
      title: "Machine Learning project",
      subtitle: "Udemy",
      logo_path: "iit.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-0a04d3c0-2772-4993-b2f1-33d85af5c66d/",
      alt_name: "Workshop",
      color_code: "#2AAFED",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "As a seasoned Full Stack and CyberSecurity Engineer with over 10 years of experience, I have worked extensively across web development, backend architecture, DevOps, and data engineering. I specialize in delivering secure, scalable, and efficient solutions for web and mobile platforms. With a strong focus on cybersecurity, compliance, and quality assurance, I am committed to helping businesses meet their goals with comprehensive, end-to-end services.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Sr. Full-Stack Web Developer & CyberSecurity Engineer",
          company: "Bodytrak",
          company_url: "https://bodytrak.co/",
          logo_path: "crest.png",
          duration: "Jan 2023 - Present",
          location: "London, United Kingdom",
          description: `As a key team member, I led the integration of advanced in-ear sensors for continuous monitoring of core body temperature (CBT), heart rate (HR), and heart rate variability (HRV), ensuring non-invasive, real-time data for early detection of heat stress and fatigue. I developed the geolocation and geofencing features using HERE Maps, enabling multi-level tracking with X, Y, and Z coordinates. I also designed and implemented the man down detection and SOS alert systems for worker safety. My work involved optimizing RESTful API integration for seamless data flow across platforms, ensuring flexible and scalable monitoring. I implemented robust security protocols, including end-to-end encryption, MFA, and role-based access control, ensuring compliance with HIPAA, GDPR, ISO 27001, and other standards. For Bodytrak 1 IS, I ensured ATEX and IECEx certification for use in hazardous environments, integrating multi-sensor systems while maintaining intrinsically safe design.`,
          color: "#0071C5",
        },
        {
          title: "Sr. Software Developer",
          company: "Point Laz Mining LiDAR Expert",
          company_url: "https://www.pointlaz.com/en",
          logo_path: "valora.jpeg",
          duration: "Jan 2021 - Jan 2022",
          location: "Quebec, Canada",
          description: `
          As a Sr. Python Developer & Team Lead, I serve as a full-stack developer with a focus on DevOps, working with a range of technologies such as Python, Go, Rust, and Raspberry Pi. In this capacity, I lead a team responsible for developing and maintaining advanced data pipelines while delivering innovative solutions that enhance safety and operational efficiency within the underground mining sector. My key responsibilities include optimizing full-stack solutions, streamlining deployment processes through DevOps initiatives, and implementing security measures to ensure compliance with industry standards. I also collaborate closely with cross-functional teams to drive innovation and improve safety practices in mining operations.

          Among my key achievements, I spearheaded the development of cutting-edge mining solutions that challenged conventional methods, enhancing real-time monitoring and alert systems to boost both safety and performance. I also implemented advanced data pipelines, providing critical insights that drove positive transformations within the sector. Moreover, my leadership in DevOps initiatives significantly improved deployment efficiency and system reliability, helping the organization achieve its goals.
          `,
          // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#0071C5",
        },
        // {
        //   title: "Node.js Backend Developer (Intern)",
        //   company: "Valora Infotech",
        //   company_url: "http://valorainfotech.com/",
        //   logo_path: "valora.jpg",
        //   duration: "Oct 2020 - Mar 2021",
        //   location: "Surat, Gujarat",
        //   description: `Focus on change request functions in close collaboration with the project manager and team leads.
        //   Developed new and maintained existing applications.
        //   Increased productivity and problem-solving technics by 20%.
        //   We primarily used PERN and MERN Stack. 80% of my work was doing backend development and the remaining 20% was DevOps-related tasks.
        //   `,
        //   // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
        //   color: "#0071C5",
        // },
        {
          title: "Full Stack Python Developer",
          company: "codeFulcrum",
          company_url: "https://codefulcrum.com/",
          logo_path: "nightowls.png",
          duration: "Jul 2019 - Mar 2021",
          location: "Lahore, Pakistan",
          description: `
          As a Sr. Python Developer & Team Lead at CodeFulcrum, I specialize in developing mobile, web, and desktop applications using Python, React, and React Native. In this service-based company, I oversee the creation of cutting-edge software solutions that not only meet client requirements but also exceed their expectations in terms of quality and innovation. My role involves leading a team of developers to deliver visually appealing and technically advanced products while collaborating with cross-functional teams to design and implement impactful solutions. I ensure that all deliverables align with client needs and adhere to best practices in software development.

          Among my key achievements, I have successfully led the development of several high-profile projects, consistently delivering on time and within budget. I have implemented best practices in areas such as code quality, testing, and deployment, which significantly improved overall project efficiency. My focus on innovation and quality has enhanced client satisfaction, and I have fostered a collaborative and growth-oriented team environment by mentoring junior developers and promoting continuous learning within the team.
          `,
          // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Part Time & Volunteer",
      experiences: [
        {
          title: "Fullstack Engineer (Django+React)",
          company: "Optery",
          company_url: "https://www.optery.com/",
          logo_path: "optery.png",
          duration: "October 2021 - February 14, 2022",
          location: "Work From Home",
          description:
            "I played a key role in developing and enhancing Optery’s data removal technology, focusing on automating the opt-out process from top data broker platforms. My work involved building systems to efficiently identify and remove personal information, such as home addresses, emails, and phone numbers, from over 340+ data broker sites. Through the integration of sophisticated automation and manual privacy agent oversight, I contributed to a solution that provides continuous monthly scans, visual evidence-based reporting, and robust protection against PII-based threats like identity theft and phishing. This project significantly enhances personal data protection, equipping users with a powerful tool to safeguard their privacy online.",
          color: "#FBBD18",
        },
        {
          title: "Postman New Backend Training Volunteer ",
          company: "Postman",
          company_url: "https://www.postman.com/",
          logo_path: "postman.png",
          duration: "Feb 2020 - March 2020",
          location: "Work From Home",
          description:
            "As a volunteer, I took the initiative to mentor new backend developers on testing APIs using Postman." +
              " I organized hands-on workshops where I introduced them to Postman, demonstrating how to create and manage API requests, " +
              "verify responses, and automate testing workflows. I guided them through best practices for structuring test suites," +
              " utilizing environment variables, and generating documentation for APIs. Through this initiative, I helped developers gain confidence in testing their backend endpoints, ensuring efficient debugging, smooth API integration, and robust backend services in their projects.",
          color: "#D83B01",
        },
        {
          title: "GitHub Developer Program",
          company: "GitHub",
          company_url: "https://github.com/",
          logo_path: "github.png",
          duration: "Nov 2017 - Present",
          location: "Work from Home",
          description:
            "Contribute to Open Source Community and Open Source Project.",
          color: "#040f26",
        },

      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "I provide a comprehensive range of services to clients, leveraging modern technologies to deliver secure, scalable," +
      " and efficient solutions. My expertise in Data Engineering, AI, full-stack web development spans backend technologies like Golang, Node.js," +
      " python, Ruby combined with frontend frameworks such as React.js, Angular.js, and Next.js to build dynamic applications." +
      "In addition to web development, I offer penetration testing and red team assessments to identify vulnerabilities and ensure the highest levels of security for client applications and infrastructures. My cybersecurity services adhere to compliance standards like ISO 27001 and SOC 2, providing clients with peace of mind regarding their security posture.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "arslan.jpg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
    link: "https://x.com/ArslanR09197358",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Heat Stress Monitors for Workplace Safety",
      url: "https://bodytrak.co/",
      description: "A comprehensive safety solution for heat stress detection, fatigue monitoring, lone worker safety, noise metering, hearing protection and more",
       languages: [
         {
           name: "Python",
           iconifyClass: "logos-python",
         },
         {
           name: "Django",
           iconifyClass: "logos-django",
         },
         {
           name: "DevOps",
           iconifyClass: "vscode-icons:file-type-docker",
         },
         {
           name: "ISO Compliance",
           iconifyClass: "fluent:shield-checkmark-24-filled",
         },

       ],
      links: [

        {
          name: "Live",
          url:
            "https://bodytrak.co/",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "1",
      name: "Mines safety",
      url: "https://www.pointlaz.com/",
      description:
        "An innovative mine monitoring system that delivers a substantial and enduring impact.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },

        {
          name: "DevOps",
          iconifyClass: "vscode-icons:file-type-docker",
        },
        {
          name: "Artificial Intelligence",
          iconifyClass: "logos-ai",
        },

      ],
      links: [
        {
          name: "Live",
          url: "https://www.pointlaz.com/",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "2",
      name: "Fitness Rewards Systemn",
      url: "https://herotrainer.io/",
      description:
        "Support our mission to fight the global health crisis.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },

        {
          name: "DevOps",
          iconifyClass: "vscode-icons:file-type-docker",
        },
        {
          name: "Artificial Intelligence",
          iconifyClass: "logos-ai",
        },
        {
          name: "React Native",
          iconifyClass: "logos-react",
        }


      ],
      links: [
        {
          name: "Live",
          url: "https://rajmahadevwala1.wixsite.com/adoptisity",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "3",
      name: "AV Automotive Inventory Merchandising",
      url: "https://www.autoverify.com/av-aim",
      description:
        "Support our mission to fight the global health crisis.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },

        {
          name: "DevOps",
          iconifyClass: "vscode-icons:file-type-docker",
        },
        {
          name: "Artificial Intelligence",
          iconifyClass: "logos-ai",
        },
        {
          name: "React Native",
          iconifyClass: "logos-react",
        }


      ],
      links: [
        {
          name: "Live",
          url: "https://www.autoverify.com/av-aim",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "4",
      name: "MAKO Sports Lighting",
      url: "https://mako-m3.com/#",
      description:
        "Revolutionizing the Sports Lighting Experience",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },

        {
          name: "DevOps",
          iconifyClass: "vscode-icons:file-type-docker",
        },
        {
          name: "Artificial Intelligence",
          iconifyClass: "logos-ai",
        },
        {
          name: "React Native",
          iconifyClass: "logos-react",
        }


      ],
      links: [
        {
          name: "Live",
          url: "https://mako-m3.com/#",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "5",
      name: "Trak4 GPS Tracker",
      url: "https://trak-4.com/",
      description:
        "Know where your people and things are in real time. Knowledge is power.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },

        {
          name: "DevOps",
          iconifyClass: "vscode-icons:file-type-docker",
        },
        {
          name: "Artificial Intelligence",
          iconifyClass: "logos-ai",
        },
        {
          name: "React Native",
          iconifyClass: "logos-react",
        }


      ],
      links: [
        {
          name: "Live",
          url: "https://trak-4.com/",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "6",
      name: "Optery",
      url: "https://www.optery.com/",
      description:
        "Remove your home address, phone and other private info from Google, and 570+ sites",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },

        {
          name: "DevOps",
          iconifyClass: "vscode-icons:file-type-docker",
        },
        {
          name: "Artificial Intelligence",
          iconifyClass: "logos-ai",
        },
        {
          name: "React Native",
          iconifyClass: "logos-react",
        }


      ],
      links: [
        {
          name: "Live",
          url: "https://www.optery.com/",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "7",
      name: "Optery",
      url: "https://earth.fastmap.ai/",
      description:
        "Virtual Site visit project with 3D asserts and collaboration functions",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },

        {
          name: "DevOps",
          iconifyClass: "vscode-icons:file-type-docker",
        },
        {
          name: "Artificial Intelligence",
          iconifyClass: "logos-ai",
        },
        {
          name: "React Native",
          iconifyClass: "logos-react",
        }


      ],
      links: [
        {
          name: "Live",
          url: "https://earth.fastmap.ai/",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "8",
      name: "Lykrepair Mobile",
      url: "https://lykrepair.com/",
      description:
        "Singapore’s Trusted Expert for Phone Repair",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },

        {
          name: "DevOps",
          iconifyClass: "vscode-icons:file-type-docker",
        },
        {
          name: "Artificial Intelligence",
          iconifyClass: "logos-ai",
        },
        {
          name: "React Native",
          iconifyClass: "logos-react",
        }


      ],
      links: [
        {
          name: "Live",
          url: "https://lykrepair.com/",
          iconifyClass: "mdi:web",
        },
      ],
    },


  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
